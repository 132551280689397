import React from 'react'
import { HeadFC, Link, PageProps } from 'gatsby'
import { css } from '../../../styled-system/css'
import { packages } from '@dup/products'
import { HeaderAndFooterLayout } from '../../layouts/HeaderAndFooter'
import { Gift, MapPin } from 'lucide-react'
import { PackageImages } from '../../components/photographie/order/PackageImages'
import ResponsiveImage from '../../components/photographie/ResponsiveImage'

const SuccessPage: React.FC<PageProps> = ({ location }) => {
  const urlParams = new URLSearchParams(location.search)

  const pkgId = urlParams.get('pkg')
  const date = urlParams.get('date')
  const sponsorship = urlParams.get('sponsorship')

  const pkg = packages.find((_pkg) => _pkg.id === pkgId)

  if (!date || !pkg) {
    return null
  }

  const dateObj = new Date(date.replace(' ', '+'))
  var dateString = dateObj.toLocaleDateString('fr-FR', {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  })

  dateString = dateString.replace(':', 'h')
  dateString = dateString.replace(/\s\w/g, (l) => l.toUpperCase())

  return (
    <HeaderAndFooterLayout>
      <div
        className={css({
          display: 'flex',
          flexDir: 'column',
          gap: '1rem',
          margin: '5rem 0',
          alignItems: 'center',
          xlDown: {
            margin: '2.5rem 0',
          },
        })}
      >
        <h1
          className={css({
            fontFamily: "'Alex Brush' !",
            fontSize: '5.1rem',
            lineHeight: '1',
            textAlign: 'center',
            xlDown: {
              fontSize: '3.5rem',
            },
          })}
        >
          Merci !
        </h1>
        <h2
          className={css({
            color: '#19213D',
            fontSize: '2rem',
            textAlign: 'center',
            '& span': {
              fontWeight: '600',
              userSelect: 'all !',
            },
            xlDown: {
              fontSize: '1.5rem',
            },
            lgDown: {
              fontSize: '1.1rem',
            },
          })}
        >
          Votre <span>séance photo {pkg.label}</span> a bien été réservée
          <br />
          RDV le <span>{dateString}</span> au studio
        </h2>

        <p
          className={css({
            color: '#19213D',
            fontSize: '1.25rem',
            textAlign: 'center',
            xlDown: {
              fontSize: '1rem',
            },
            lgDown: {
              fontSize: '0.8rem',
              width: '90%',
            },
          })}
        >
          Un email de confirmation de votre réservation vous été envoyé sur
          votre boite mail.
        </p>
      </div>

      <div
        className={css({
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        })}
      >
        <div
          className={css({
            pos: 'relative',
            width: '90%',
            overflow: 'hidden',
            // TODO: REMOVE
          })}
        >
          <ResponsiveImage
            className={css({
              pos: 'absolute',
              translate: '-50% -50%',
              left: '50%',
              top: '50%',
              w: '15vw',
              h: 'auto',
              maxH: '15vw',
              border: '.25rem solid #F2D98D',
              xlDown: {
                display: 'none',
              },
              objectFit: 'cover',
              aspectRatio: '1/1',
            })}
            relativePath={`packages/${pkgId}/${pkg.images.at(0)?.name}`}
          />

          <div
            className={css({
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              xlDown: {
                flexDir: 'column',
                gap: '2rem',
              },
              lgDown: {
                gap: '1.5rem',
              },
            })}
          >
            <div
              className={css({
                flex: '1',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'start',
              })}
            >
              <div
                className={css({
                  width: '75%',
                  padding: '2.5rem 0',
                  xlDown: {
                    width: '100%',
                    padding: '0',
                  },
                })}
              >
                <div
                  className={css({
                    display: 'inline-flex',
                    alignItems: 'center',
                    gap: '1rem',
                    lgDown: {
                      display: 'flex',
                      flexDir: 'column',
                    },
                  })}
                >
                  <Gift
                    className={css({
                      w: '3rem',
                      h: '3rem',
                      xlDown: {
                        w: '2rem',
                        h: '2rem',
                      },
                    })}
                    color="#594A2E"
                  />
                  <h2
                    className={css({
                      fontSize: '2rem',
                      textWrap: 'nowrap',
                      xlDown: {
                        fontSize: '1.5rem',
                      },
                      lgDown: {
                        fontSize: '1.2rem',
                      },
                    })}
                  >
                    Parrainez un proche et profitez de réductions !
                  </h2>
                </div>

                <p
                  className={css({
                    display: 'flex',
                    flexDir: 'column',
                    gap: '1.25rem',
                    fontSize: '1rem',
                    mt: '1rem',
                    '& span span': {
                      fontWeight: '600',
                    },
                    xlDown: {
                      gap: '1rem',
                      fontSize: '0.9rem',
                      textAlign: 'center',
                    },
                    lgDown: {
                      fontSize: '0.8rem',
                    },
                  })}
                >
                  <span>
                    Partagez votre amour pour la photographie en invitant vos
                    amis et votre famille à réserver leur propre séance photo
                    chez Digit Universe.
                  </span>
                  <span>
                    En utilisant votre lien de parrainage unique, ils
                    bénéficieront d&apos;une réduction sur leur première
                    réservation.
                  </span>
                  <span>
                    <span>Récompenses pour Vous et Vos Parrainés :</span> En
                    tant que parrain, vous recevrez également une réduction sur
                    votre prochaine séance, comme un merci de notre part pour
                    avoir partagé l&apos;expérience Digit Universe.
                  </span>
                  <span>
                    <span>Plus de Bonus :</span> Plus vous parrainez, plus vous
                    gagnez ! Accédez à des offres spéciales, des cadeaux
                    exclusifs, et bien plus encore.
                  </span>
                  <span>
                    Ne manquez pas cette opportunité de rendre vos souvenirs
                    encore plus spéciaux. Partagez votre lien de parrainage dès
                    aujourd&apos;hui et commencez à bénéficier ensemble de tous
                    les avantages que Digit Universe a à offrir.
                  </span>
                </p>
              </div>
            </div>
            <div
              className={css({
                flex: '1',
                width: '100%',
                border: '.25rem solid #F2D98D',
              })}
            >
              <ResponsiveImage
                className={css({
                  objectFit: 'cover',
                  w: '100%',
                  aspectRatio: '2/1',
                })}
                relativePath={`packages/${pkgId}/${pkg.images.at(1)?.name}`}
              />
            </div>
          </div>
          <div
            className={css({
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              xlDown: {
                flexDir: 'column',
                gap: '2rem',
                marginTop: '2.5rem',
              },
              lgDown: {
                gap: '1.5rem',
                marginTop: '1.5rem',
              },
            })}
          >
            <div
              className={css({
                flex: '1',
                width: '100%',
                border: '.25rem solid #F2D98D',
              })}
            >
              <ResponsiveImage
                className={css({
                  objectFit: 'cover',
                  w: '100%',
                  aspectRatio: '2/1',
                })}
                relativePath={`packages/${pkgId}/${pkg.images.at(2)?.name}`}
              />
            </div>

            <div
              className={css({
                flex: '1',
                display: 'flex',
                flexDir: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                gap: '1rem',
              })}
            >
              <div
                className={css({
                  display: 'inline-flex',
                  gap: '0.5rem',
                  alignItems: 'center',
                  justifyContent: 'center',
                  xlDown: {
                    display: 'flex',
                    flexDir: 'column',
                    gap: '0.25rem',
                  },
                })}
              >
                <MapPin
                  className={css({
                    w: '3rem',
                    h: '3rem',
                    xlDown: {
                      w: '2rem',
                      h: '2rem',
                    },
                  })}
                  color="#594A2E"
                />
                <h2
                  className={css({
                    fontSize: '2rem',
                    xlDown: {
                      fontSize: '1.5rem',
                    },
                    lgDown: {
                      fontSize: '1.2rem',
                    },
                  })}
                >
                  Où nous trouver
                </h2>
              </div>

              <p
                className={css({
                  fontSize: '1rem',
                  xlDown: {
                    fontSize: '0.9rem',
                  },
                  lgDown: {
                    fontSize: '0.8rem',
                  },
                })}
              >
                Notre studio photo se trouve au :<br />
                <span
                  className={css({
                    fontWeight: 'bold',
                    userSelect: 'all !',
                  })}
                >
                  29 rue du colonel Arnaud Beltrame, 59152 Chéreng
                </span>
              </p>

              <a
                href="https://bit.ly/3LvTrCs"
                target="_blank"
                className={css({
                  h: '100%',
                  xlDown: {
                    h: 'auto',
                    w: '100%',
                  },
                })}
              >
                <ResponsiveImage
                  className={css({
                    h: '100%',
                    xlDown: {
                      h: 'auto',
                      w: '100%',
                    },
                  })}
                  relativePath="map.png"
                />
              </a>
            </div>
          </div>
        </div>
      </div>

      <p
        className={css({
          fontFamily: "'Alex Brush' !",
          fontSize: '2.1rem',
          lineHeight: '1',
          textAlign: 'center',
          width: '50%',
          margin: '4rem auto',
          xlDown: {
            width: '90%',
            fontSize: '1.75rem',
            margin: '3rem auto',
          },
          lgDown: {
            fontSize: '1.5rem',
            margin: '2rem auto',
          },
        })}
      >
        Merci de choisir Digit Universe pour immortaliser vos moments. Nous
        avons hâte de vous voir et de créer ensemble des souvenirs inoubliables.
      </p>

      <PackageImages
        className={css({
          m: '2.5rem 0 5rem',
          w: '100%',
          xlDown: {
            m: '2.5rem 0',
          },
          lgDown: {
            m: '2rem 0',
          },
        })}
        id={pkg.id}
        amount={4}
      />
    </HeaderAndFooterLayout>
  )
}

export default SuccessPage

export const Head: HeadFC = () => <title>Success Page</title>
